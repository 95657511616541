import gql from 'graphql-tag';

export const CHECK_OUT = gql`
mutation updateCheckInCheckOut($data: CheckInUpdateInput!, $userId: String!){
  updateCheckInCheckOut(data: $data, where: { userId: $userId, checkOut: null }){
    checkIn
    checkOut
    duration
    transaction{
      id
    }
    user{
      id
      email
    }
  }
}
`;

export const CREATE_TRANSACTION = gql`
mutation createTransaction($data: TransactionCreateInput!){
  createTransaction(data: $data){
    id
  }
}
`;