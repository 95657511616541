import React, { useContext } from 'react'
import { Toast } from 'antd-mobile';
import Cookies from 'universal-cookie'
import { withRouter } from 'react-router-dom'
import { AppContext } from '../../../AppContext'
import { client } from '../../../apollo';
import { LOGOUT } from '../graphql/Mutations'
import Meta from '../../../components/Meta';
import Loader from '../../../components/loader';

const Logout = (props) => {
    const cookies = new Cookies()
    const { dispatch } = useContext(AppContext)

    client.mutate({ mutation: LOGOUT })
        .then(response => {
            cookies.remove('connect.sid')
            cookies.remove('token')
            dispatch({ type: 'CURRENT_USER', data: {} })
            props.history.replace('/')
        }).catch(error => {
            console.log({ error })
            Toast.fail('Unable to logout', 3, null, false)
            props.history.replace('/')
        })

    return (
        <div>
            <Meta title='Logout' description='' />
            <Loader />
        </div>
    )
}

export default withRouter(Logout)