import gql from 'graphql-tag';

export const SET_PLAN = gql`mutation createUserSubscription($data: UserSubscriptionCreateInput!){
  createUserSubscription(data: $data){
    id
  }
}`;

export const MAKE_PAYMENT = gql`
mutation createPayment($data: PaymentCreateInput!){
  createPayment(data: $data){
    id
  }
}
`;