import React, { useState, useContext, Fragment, useEffect, } from 'react';
import { Button, WhiteSpace, WingBlank, Flex, Toast, } from 'antd-mobile';
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks';
import { client } from '../../apollo'
import { AppContext } from '../../AppContext'
import { MAKE_PAYMENT, SET_PLAN } from './qraphql/Mutations'
import { GET_PLANS } from './qraphql/Queries'
import Meta from '../../components/Meta';
import PlanCard from './components/PlanCard'
import Navbar from '../../components/navbar'
import Loader from '../../components/loader'
import ErrorMSG from '../../components/errormsg'
import './style/index.css'

const Plan = (props) => {
  const { state } = useContext(AppContext)
  const user = state.currentUser
  const isDisabled = user.hasUpcomingPlan
  const currPlanId = user && user.userSubscription && user.userSubscription.plans ? user.userSubscription.plans.id : null
  const [selectedPlan, setSelectedPlan] = useState(user && user.userSubscription && user.userSubscription.plans && user.userSubscription.plans.type && user.userSubscription.plans.type === 'PUBLIC' ? user.userSubscription.plans : null)
  // TODO: remove unnecessary code or do comment if  needed in future
  const { loading: planLoad, data: planData } = useQuery(GET_PLANS, { fetchPolicy: 'network-only' });

  // useEffect(() => {
  //   if (planData && planData.plans && selectedPlan) {
  //     setSelectedPlan(planData.plans[planData.plans.findIndex(plan => plan.id === selectedPlan.id)])
  //   }
  // }, [planData, selectedPlan])

  useEffect(() => {
    if (planData && planData.plans && currPlanId) {
      const index = planData.plans.findIndex(plan => plan.id === currPlanId)
      if (index !== -1) {
        setSelectedPlan(planData.plans[index])
      } else {
        setSelectedPlan(null)
      }
    }
  }, [planData, currPlanId])

  const handlePlan = (data) => {
    setSelectedPlan(data)
  }

  const handleSubmit = (e) => {
    if (!selectedPlan) {
      Toast.info('Please select a plan before proceeding.', 3, null, false)
    } else {
      const razorPayOptions = {
        "key": process.env.REACT_APP_RAZOR_PAY_KEY,
        "amount": ((selectedPlan.id === currPlanId && selectedPlan.renewAmount ? selectedPlan.renewAmount : selectedPlan.price) * 100), // 2000 paise = INR 20
        "name": `${selectedPlan.name} - ikoverk Lounge`,
        "description": `You have selected plan ${selectedPlan.name}`,
        "handler": (response) => {
          try {
            client.mutate({
              mutation: MAKE_PAYMENT,
              variables: {
                data: {
                  user: { connect: { id: user.id } },
                  plan: { connect: { id: selectedPlan.id } },
                  amount: parseFloat(selectedPlan.price),
                  transactionId: response.razorpay_payment_id,
                  transactionStatus: "success",
                  transactionResponse: ""
                }
              }
            }).then(() => {
              return client.mutate({
                mutation: SET_PLAN,
                variables: {
                  data: {
                    user: { connect: { id: user.id } },
                    plan: { connect: { id: selectedPlan.id } }
                  }
                }
              });
            }).then(() => {
              props.history.push("/payment")
            }).catch((error) => {
              console.log(error)
              if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
                Toast.info(error.graphQLErrors[0].message, 3, null, false)
              } else {
                Toast.info('Something went wrong!', 3, null, false)
              }
            })
          }
          catch (error) {
            console.log(error)
            if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
              Toast.info(error.graphQLErrors[0].message, 3, null, false)
            } else {
              Toast.info('Something went wrong!', 3, null, false)
            }
          }
        },
        "prefill": {
          "email": user.email || '',
          "contact": user.phone
        },
        "theme": {
          "color": "#aeca36"
        }
      }
      var razorPayRef = new window.Razorpay(razorPayOptions);
      razorPayRef.open();
    }
  }

  const content = planLoad ? (<Loader />) :
    planData && planData.plans ?
      <Fragment>
        <Meta title='Plans' />
        <WhiteSpace size='sm' />
        <WingBlank size="lg">
          {
            planData.plans.map(data => (
              <Fragment key={data.id}>
                <PlanCard
                  current={data.id === currPlanId}
                  selected={selectedPlan && selectedPlan.id === data.id}
                  {...data}
                  onClick={() => handlePlan(data.id === currPlanId ? setSelectedPlan : data)} />
                <WhiteSpace size='lg' />
              </Fragment>
            ))
          }
        </WingBlank>
        <Flex justify='center'>
          <p className='how-it-works' onClick={() => props.history.push('/how-it-works')}>How it works</p>
        </Flex>
        <WhiteSpace size='xl' />
        <WhiteSpace size='xl' />
        <WhiteSpace size='xl' />
        <WhiteSpace size='xl' />
        <Flex justify="center" className="btn-floating">
          <Button
            className={`btn ${isDisabled ? 'isDisabled' : ''}`}
            onClick={(e) => {
              isDisabled ?
                Toast.info("You already have upcoming plan!", 4, null, false)
                :
                handleSubmit(e)
            }}
          >
            {`Pay ${selectedPlan ?
              `₹ ${selectedPlan.id === currPlanId && selectedPlan.renewAmount ? selectedPlan.renewAmount : selectedPlan.price}`
              :
              ''}
                `}
          </Button>
        </Flex>
      </Fragment>
      :
      <ErrorMSG />

  return (
    currPlanId ? (<Navbar title='CHOOSE PLAN' backButton={false}>{content}</Navbar>) : (
      <><WhiteSpace size='lg' /><WhiteSpace size='sm' />
        <Flex justify='center'>
          <h2 className="page-header">CHOOSE PLAN</h2>
        </Flex>{content}
      </>)
  );
}

export default withRouter(Plan);