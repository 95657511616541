import React, { useContext, useState, useEffect, Fragment } from 'react';
import { WingBlank, Flex, WhiteSpace, ImagePicker } from 'antd-mobile';
import { useQuery } from '@apollo/react-hooks';
import { AppContext } from '../../AppContext'
import { USER_PROFILE_DETAIL } from './graphql/Queries'
import Meta from '../../components/Meta';
import Navbar from '../../components/navbar'
import Loader from '../../components/loader'
import LogHistory from './components/LogHistory';
import './style/profile.css'
import noProfileImage from '../../../src/assets/noprofileimage.png'

const Profile = (props) => {
  const { state } = useContext(AppContext)
  const user = state.currentUser
  const currentPoints = user.hasUpcomingPlan ? (user.points + user.userSubscription && user.userSubscription.lastOverDueAmount ? user.userSubscription.lastOverDueAmount : 0) : user.points
  if (!(user.firstName && user.lastName)) props.history.push('new')
  const { loading, data } = useQuery(USER_PROFILE_DETAIL, { variables: { id: user.id }, fetchPolicy: 'network-only' })
  const [file, setFile] = useState([])

  useEffect(() => {
    if (user && user.profileImage) {
      setFile([{ url: user.profileImage, id: '1' }])
    } else {
      setFile([{ url: noProfileImage, id: '1' }])
    }
  }, [user])

  return (
    loading ? <Loader /> :
      <Fragment>
        <Meta title='Profile' />
        <Navbar title='PROFILE'>
          <WingBlank>
            <Flex>
              <Flex.Item>
                <div className='picture-wrapper'>
                  <ImagePicker
                    files={file}
                    onChange={(e) => console.log(e)}
                    onImageClick={(index, fs) => console.log(index, fs)}
                    selectable={file.length < 1}
                    multiple={false}
                    length='1'
                    style={{}}
                  />
                </div>
              </Flex.Item>
              <Flex.Item className='profile-row'>
                <div className='profile-name'>{user ? `${user.firstName} ${user.lastName}` : ''}</div>
                <div className='profile-email'>
                  {user ? user.phone : ''}
                </div>
                <div className='edit-btn' onClick={() => { props.history.push('profile/edit') }}>
                  EDIT
              </div>
              </Flex.Item>
            </Flex>
            <WhiteSpace size='lg' />
          </WingBlank>
          {
            data && data.userHistories && <>
              <Flex className='header'>
                <div >
                  Account Summary
                </div>
              </Flex>
              <Flex justify='center' style={{ textAlign: "center" }}>
                <Flex.Item className='content-big'  >
                  <div >{data.userHistories.userTotalPoints ? Math.round(data.userHistories.userTotalPoints) : 0}</div>
                  <div className='content-small'>Total Funds Used</div>
                </Flex.Item>
                <Flex.Item className='content-big'>
                  <div >{data.userHistories.totalDuration ?
                    `${parseInt(data.userHistories.totalDuration / 60).toString().length === 1 ? `0${parseInt(data.userHistories.totalDuration / 60)}` : parseInt(data.userHistories.totalDuration / 60)}:${(parseInt(data.userHistories.totalDuration % 60)).toString().length === 1 ? `0${parseInt(data.userHistories.totalDuration % 60)}` : parseInt(data.userHistories.totalDuration % 60)}` : '00:00'}</div>
                  <div className='content-small'>Total Duration</div>
                </Flex.Item>
                <Flex.Item className='content-big'>
                  <div >{Math.round(currentPoints)}</div>
                  <div className='content-small'>Available Funds</div>
                </Flex.Item>
              </Flex>
            </>}
          <Flex className='header'>
            <div >Transaction History</div >
          </Flex>
          {
            data &&
              data.userHistories && data.userHistories.userHistory ?
              <LogHistory data={data.userHistories.userHistory} />
              :
              <Loader />
          }
          <WhiteSpace />
        </Navbar>
      </Fragment>
  );
}

export default Profile;