import React from 'react';
import { Flex, Button, WingBlank, WhiteSpace } from 'antd-mobile';
import { withRouter } from 'react-router-dom'
import Navbar from '../components/navbar'
import '../index.css'

function Contact(props) {
  return (
    <Navbar title='SUPPORT'>
      <div className='main-div'>
        <div>
          <WingBlank size='lg'>
            <WingBlank size='lg'>
              <WingBlank size='lg'>
                <Flex justify='center'>
                  <Flex.Item>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" width="40px" height="40px" fill="#aeca36" stroke="#aeca36" strokeWidth="0">
                      <path d="M3.217,126.14L224.87,302.686c1.579,1.331,15.821,13.047,31.13,13.047c15.377,0,29.747-11.281,31.292-12.535 l221.5-177.067c2.031-1.621,3.208-4.07,3.208-6.664C512,95.94,492.86,76.8,469.333,76.8H42.667C19.14,76.8,0,95.94,0,119.467 C0,122.069,1.186,124.518,3.217,126.14z M42.667,93.867h426.667c12.8,0,23.441,9.446,25.31,21.726L276.599,289.894 c-3.038,2.44-12.672,8.772-20.599,8.772c-7.689,0-17.109-6.502-20.284-9.165L17.357,115.584 C19.234,103.305,29.867,93.867,42.667,93.867z"></path>
                      <path d="M156.86,274.893L37.393,368.759c-3.703,2.91-4.343,8.277-1.434,11.981c1.681,2.142,4.181,3.26,6.707,3.26 c1.852,0,3.712-0.597,5.274-1.826l119.467-93.867c3.703-2.91,4.344-8.277,1.434-11.981 C165.931,272.614,160.563,271.983,156.86,274.893z"></path>
                      <path d="M355.14,274.893c-3.703-2.91-9.071-2.27-11.981,1.434s-2.27,9.071,1.434,11.981l119.467,93.867 c1.562,1.229,3.422,1.826,5.265,1.826c2.526,0,5.035-1.118,6.716-3.26c2.91-3.703,2.27-9.071-1.434-11.981L355.14,274.893z"></path>
                      <path d="M503.467,153.6c-4.71,0-8.533,3.823-8.533,8.533v230.4c0,14.114-11.486,25.6-25.6,25.6H42.667 c-14.114,0-25.6-11.486-25.6-25.6v-230.4c0-4.71-3.823-8.533-8.533-8.533S0,157.423,0,162.133v230.4 C0,416.06,19.14,435.2,42.667,435.2h426.667c23.526,0,42.667-19.14,42.667-42.667v-230.4 C512,157.423,508.177,153.6,503.467,153.6z"></path>
                    </svg>
                  </Flex.Item>
                  <Flex.Item className='flex-5'>
                    <h4 className='hiw-title'>Email Support</h4>
                    <p className='description-para'>Send an e-mail describing your queries at: <a href='mailto:ikoverklounge@gmail.com' className='contact-link'>ikoverklounge@gmail.com</a></p>
                  </Flex.Item>
                </Flex>
                <WhiteSpace size='lg' />
                <WhiteSpace size='lg' />
                <Flex justify='center'>
                  <Flex.Item>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="45px" height="45px" viewBox="0 0 413.987 413.987" fill="#aeca36" stroke="#aeca36" strokeWidth="0">
                      <path d="M379.55,349.004c4.185-11.918-3.809-24.756-13.903-29.26l-66.364-29.493c-3.184-1.411-6.987-2.152-11.004-2.152 c-8.206,0-16.438,3.047-21.47,7.937l-20.906,20.251c-3.112,3.017-8.633,5.037-13.751,5.037c-2.188,0-4.108-0.386-5.565-1.122 c-14.102-7.068-36.282-21.186-61.847-48.307c-22.704-24.085-33.472-40.573-38.511-50.165c-2.821-5.383,0.124-14.681,4.55-19.104 l18.603-18.588c7.736-7.727,11.212-21.947,7.924-32.39l-20.919-66.43c-3.176-10.098-13.903-20.556-26.441-20.556 c-17.333,0.577-41.657,6.777-59.171,33.045c-27.058,40.606-25.43,118.767,24.38,174.269 c46.334,51.618,115.143,108.607,115.742,109.105c1.485,1.341,36.97,32.905,88.882,32.905c5.002,0,10.11-0.31,15.219-0.914 C351.504,406.228,372.228,369.992,379.55,349.004z M293.555,401.287c-4.565,0.554-9.201,0.838-13.792,0.838 c-46.723,0-79.687-28.73-81.149-30.025c-2.829-2.356-69.939-58.234-114.653-108.05c-44.564-49.652-48.048-122.701-23.336-159.778 c14.701-22.056,35.282-27.279,49.967-27.776c5.989,0.025,12.695,6.614,14.49,12.278l20.927,66.43 c1.965,6.256-0.371,15.808-5.01,20.447l-18.603,18.595c-7.602,7.591-12.096,22.666-6.652,32.989 c5.395,10.273,16.788,27.762,40.367,52.791c26.75,28.365,50.206,43.274,65.158,50.779c3.066,1.539,6.835,2.356,10.902,2.356 c8.231,0,16.655-3.199,21.973-8.368l20.9-20.256c4.189-4.043,14.081-5.819,19.429-3.453l66.354,29.492 c5.053,2.25,9.455,9.08,7.551,14.514C361.956,363.471,343.705,395.224,293.555,401.287z"></path>
                      <path d="M188.867,148.828c0.663,1.439,1.848,2.521,3.326,3.064c0.655,0.246,1.351,0.373,2.044,0.373 c2.473,0,4.702-1.556,5.555-3.879c16.358-44.438-6.487-93.907-50.925-110.261c-2.993-1.097-6.51,0.538-7.606,3.509 c-0.546,1.482-0.49,3.095,0.173,4.524c0.665,1.439,1.848,2.529,3.333,3.082c38.316,14.102,58.011,56.747,43.909,95.052 C188.131,145.778,188.194,147.391,188.867,148.828z"></path>
                      <path d="M224.352,172.369c0.655,0.239,1.346,0.355,2.036,0.355c2.468,0,4.713-1.556,5.565-3.877 c25.004-67.903-9.917-143.481-77.833-168.493c-2.994-1.13-6.51,0.546-7.607,3.517c-0.546,1.485-0.475,3.095,0.19,4.524 c0.663,1.44,1.849,2.529,3.326,3.075c61.778,22.752,93.542,91.513,70.808,153.284C219.706,167.812,221.27,171.224,224.352,172.369 z"></path>
                    </svg>
                  </Flex.Item>
                  <Flex.Item className='flex-5'>
                    <h4 className='hiw-title'>Phone Support</h4>
                    <p className='description-para'>Call us on: <a href='tel:+919723434004' className='contact-link'>+91-9723434004</a></p>
                  </Flex.Item>
                </Flex>
              </WingBlank>
            </WingBlank>
          </WingBlank>
          <Flex justify="center" style={{ marginTop: '7vh' }}>
            <Button onClick={() => { props.history.goBack() }} className='btn'>Got It</Button>
          </Flex>
        </div>
      </div >
    </Navbar>
  )
}
export default withRouter(Contact)
