import React, { useContext, useState } from 'react'
import { Flex, WingBlank, WhiteSpace, InputItem, Button, Toast, Modal } from 'antd-mobile';
import { withRouter } from 'react-router-dom'
import { AppContext } from '../../AppContext'
import { CREATE_TOPUP } from './graphql/Mutations';
import { GET_CURRENT_USER } from '../../modules/profile/graphql/Queries';
import { client } from '../../apollo'
import './style/index.css'

function TopupModal(props) {
  const { visible, setShowModal } = props
  const { dispatch, state: { currentUser } } = useContext(AppContext)
  const [amount, setAmount] = useState(0)

  function handleTopup() {
    if (amount < 1) {
      Toast.info("Please enter valid amount.", 4, null, false)
    } else {
      setShowModal(false)
      const razorPayOptions = {
        "key": process.env.REACT_APP_RAZOR_PAY_KEY,
        "amount": (amount * 100), // 2000 paise = INR 20
        "name": `TOPUP - ikoverk Lounge`,
        "description": `These Funds have a lifetime validity.`,
        "handler": (response) => {
          try {
            client.mutate({
              mutation: CREATE_TOPUP,
              variables: {
                data: {
                  user: { connect: { id: currentUser.id } },
                  topUpAmount: parseFloat(amount),
                  payment: {
                    create: {
                      user: { connect: { id: currentUser.id } },
                      subscription: { connect: { id: currentUser.userSubscription.id } },
                      amount: parseFloat(amount),
                      transactionId: response.razorpay_payment_id,
                      transactionStatus: "success",
                      transactionResponse: "",
                      createdBy: { connect: { id: currentUser.id } }
                    }
                  }
                }
              }
            }).then((res) => {
              return client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
            })
              .then(data => {
                dispatch({ type: 'CURRENT_USER', data: data.data.currentUser })
                setShowModal(false)
                setAmount(0)
              })
              .catch((error) => {
                console.log(error)
                if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
                  Toast.info(error.graphQLErrors[0].message, 3, null, false)
                } else {
                  Toast.info('Something went wrong!', 3, null, false)
                }
              })
          }
          catch (error) {
            console.log(error)
            if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
              Toast.info(error.graphQLErrors[0].message, 3, null, false)
            } else {
              Toast.info('Something went wrong!', 3, null, false)
            }
          }
        },
        "prefill": {
          "email": currentUser.email || '',
          "contact": currentUser.phone
        },
        "theme": {
          "color": "#aeca36"
        }
      }
      var razorPayRef = new window.Razorpay(razorPayOptions);
      razorPayRef.open();
    }
  }

  return (
    <Modal visible={visible} className="top-up-modal">
      <WingBlank>
        <Flex style={{ fontSize: '1.2em' }}>How much do you want to add?</Flex>
        <WhiteSpace />
        <Flex>
        </Flex>
        <div className='amount-input'>
          <InputItem
            type='digit'
            onChange={(e) => parseInt(e) ? setAmount(parseInt(e)) : setAmount(0)}
            onKeyUp={(e) => {
              if ((e.keyCode === 13 || e.which === 13) && e.target.value.length !== '') {
                e.target.blur()
              }
            }}
            placeholder='Enter Amount'
            value={amount}
          >
            <div style={{ fontWeight: '500', fontSize: '1.2em' }}>₹</div>
          </InputItem>
        </div>
        <WhiteSpace />
        <WhiteSpace />
        <Flex justify='center' className='fixed-amount-flex'>
          <span className={`fixed-amount-block ${amount === 100 ? 'active' : ''}`} onClick={() => setAmount(100)}>+  <span className='price-symbol'>₹</span> 100 </span>
          <span className={`fixed-amount-block ${amount === 200 ? 'active' : ''}`} onClick={() => setAmount(200)}>+  <span className='price-symbol'>₹</span> 200 </span>
          <span className={`fixed-amount-block ${amount === 500 ? 'active' : ''}`} onClick={() => setAmount(500)}>+  <span className='price-symbol'>₹</span> 500 </span>
          <span className={`fixed-amount-block ${amount === 1000 ? 'active' : ''}`} onClick={() => setAmount(1000)}>+  <span className='price-symbol'>₹</span> 1000 </span>
        </Flex>
        <WhiteSpace /><WhiteSpace />
        <WhiteSpace /><WhiteSpace />
        <Flex justify='center' className='topup-note'>Note: These Funds have a lifetime validity.</Flex>
        <WhiteSpace />
        <WhiteSpace />
        <Flex>
          <Flex.Item>
            <Button onClick={() => setShowModal(false)}>CANCEL</Button>
          </Flex.Item>
          <Flex.Item>
            <Button onClick={() => handleTopup()}>ADD</Button>
          </Flex.Item>
        </Flex>
      </WingBlank>
    </Modal>
  )
}

export default withRouter(TopupModal)