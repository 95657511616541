import React from 'react';
import { Router, Switch, Route } from 'react-router-dom'
import Logout from './modules/auth/pages/Logout'
import GuardedRoute from './GuardedRoute';
import innerRoutes from './innerRoutes';
import history from './history'

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/logout' component={Logout} />
        <GuardedRoute path='/' component={innerRoutes} />
      </Switch>
    </Router>
  );
}

export default Routes;
