import gql from 'graphql-tag'

export const GET_PLANS = gql`
query{
  plans(where:{isDisabled: false, type: PUBLIC, deletedAt: null}){
    id
    name
    points
    validity
    renewAmount
    price
  }
}
`;