import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo'
import App from './App';
import { AppContextProvider } from './AppContext'
import * as serviceWorker from './serviceWorker';
import { client } from './apollo'
import 'antd-mobile/dist/antd-mobile.css';
import './index.css';
// TODO: always add direct library on top then custom files, css and images file on last
// TODO: always use tab size/spaces 2 (for all files and projects)
// TODO: do check on all files

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </ApolloProvider>,
  document.getElementById('root'));

serviceWorker.register();
