import React, { Fragment } from 'react';
import { WingBlank, } from 'antd-mobile';
import Navbar from '../../components/navbar'
import CurrentPlan from './components/CurrentPlan'
import RestaurantList from '../restaurant/index'
import Meta from '../../components/Meta';

const Home = (props) => {
  return (
    <Fragment>
      <Meta title='Home' />
      <Navbar title='IKOVERK LOUNGE'>
        <CurrentPlan />
        <WingBlank>
          <RestaurantList />
        </WingBlank>
      </Navbar>
    </Fragment >
  )
}

export default Home;