import React, { useEffect, useContext } from 'react';
import { Flex } from 'antd-mobile';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { AppContext } from '../../../AppContext'
import { GET_CHECKED_IN } from '../qraphql/Queries'
import Loader from '../../../components/loader'
import ErrorMsg from '../../../components/errormsg';
import CheckInTimer from './CheckInTimer'
import '../style/index.css'
import placeHolderLogo from '../../../assets/restaurant-placeholder.jpg';

const CheckInDetails = (props) => {
	const { state } = useContext(AppContext)
	const userId = state.currentUser.id
	const name = `${state.currentUser.firstName} ${state.currentUser.lastName}`
	const { restaurantData } = props
	const { loading, data, error } = useQuery(GET_CHECKED_IN, { variables: { id: userId }, fetchPolicy: 'network-only' });

	useEffect(() => {
		if ((!loading || error) && data && data.checkIns && data.checkIns.length === 0) {
			props.history.push('/')
		}
	}, [data, loading, error, props.history])

	return (
		loading ?
			<Loader />
			:
			data && data.checkIns[0] ?
				<div style={{ backgroundColor: 'white' }}>
					<Flex justify='center' className='title' >
						<div className='img-div'>
							<img alt='logo' src={restaurantData.logo || placeHolderLogo} style={{ height: '50px', width: '50px' }} />
						</div>
						<div className='details'>
							<div className='name'>
								{restaurantData.name}
							</div>
							<div className='location'>
								{restaurantData.address}
							</div>
						</div>
					</Flex>
					<Flex justify='center' className='checkin-header'>
						{loading || error ? <Loader /> : (<div className='text-center'>{name} checked in at {moment(data && data.checkIns[0].checkIn).format("hh:mm A")}</div >)}
					</Flex>
					<Flex justify='center' className='checkin-time'>
						{!loading && !error && data && data.checkIns[0] && data && data.checkIns[0].checkIn &&
							<CheckInTimer time={data && data.checkIns[0].checkIn} loading={loading} />
						}
					</Flex>
				</div >
				:
				<ErrorMsg />);
}

export default withRouter(CheckInDetails);