import React, { Component } from 'react'
import * as firebase from 'firebase/app'
import ReactGA from 'react-ga'
import 'firebase/auth'
import Routes from './routes'

const firebaseConfig = {
  apiKey: "AIzaSyAYVUDVcM0en9nLnQwhSozvxRACu3tCWaE",
  authDomain: "ikoverk-pass.firebaseapp.com",
  databaseURL: "https://ikoverk-pass.firebaseio.com",
  projectId: "ikoverk-pass",
  storageBucket: "ikoverk-pass.appspot.com",
  messagingSenderId: "822051804580",
  appId: "1:822051804580:web:f79641d8726667558c9d31",
  measurementId: "G-FVBKRGERTM"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_TAG);
ReactGA.pageview(window.location.pathname + window.location.search)

export default class App extends Component {
  render() {
    return <Routes />
  }
}
