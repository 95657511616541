import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import history from './history'
import LandingPage from './modules/auth'
import Login from './modules/auth/pages/Login'
import Register from './modules/auth/pages/Register'
import HowItWorks from './modules/plan/components/HowItWorks';

const LandingRoutes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/register/:code' component={Register} />
        <Route exact path='/register' component={Register} />
        <Route exact path='/how-it-works' component={HowItWorks} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
}

export default LandingRoutes;
