import gql from 'graphql-tag'

export const GET_CHECKED_IN = gql`
query checkIns($id: ID!){
  checkIns(
    where: { user: {id: $id}, checkOut: null }
  ) {
    id
    checkIn
    restaurant{
      id
    }
  }
}
`;