import React from 'react';
import { Flex } from 'antd-mobile'

const Loader = () => {

  return (
    <Flex justify='center' style={{ width: '100%', height: '100%' }}>
      <div className="loading-example" style={{ width: '100%' }}>
        <div className="align" style={{ width: '100%' }}>
          <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", display: "block" }} width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <path d="M9 50A41 41 0 0 0 91 50A41 42.8 0 0 1 9 50" fill="#aeca36" stroke="none" transform="rotate(154.719 50 50.9)">
              <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50.9;360 50 50.9"></animateTransform>
            </path>
          </svg>
          <span style={{ marginTop: 8, width: '100%', textAlign: 'center', color: '#dddddd' }}>Loading...</span>
        </div>
      </div>
    </Flex>
  )
};
export default Loader