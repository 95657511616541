import gql from 'graphql-tag'

export const GET_RESTAURANTS_LIST = gql`
query restaurants($isDisabled: Boolean){
  restaurants(where: {isDisabled: $isDisabled}){
    id
    name
    address
    discount
    logo
    capacity
    mapUrl
    isDisabled
    restaurantTimings
    minCheckinPoints
  }
}
`;

export const GET_RESTAURANT = gql`
query restaurant($id: ID!){
  restaurant(where: { id: $id }){
    name
    address
    mapUrl
    restaurantTimings
    logo
    images
    Amenity
    discount
    # minOrder
    minCheckinPoints
  }
}
`;