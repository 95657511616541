import React, { useState, useEffect, useContext } from 'react';
import { Button, WhiteSpace, WingBlank, InputItem, Flex, List, Toast, ImagePicker } from 'antd-mobile';
import Compressor from 'compressorjs';
import { withRouter } from 'react-router-dom';
import { client } from '../../../apollo';
import { AppContext } from '../../../AppContext'
import Loader from '../../../components/loader';
import { GET_CURRENT_USER, GET_SIGNED_URLS } from '../graphql/Queries'
import { UPDATE_USER } from '../graphql/Mutations'
import { fileUpload } from '../../../components/fileUpload'
import '../style/profile.css'

const ProfileForm = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const multiple = false
  const user = state.currentUser
  const userId = user.id
  const phone = user.phone
  const [updateImage, setUpdateImage] = useState(false)
  const [file, setFile] = useState([])
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const allowEdit = props.allowEdit;

  useEffect(() => {
    if (user) {
      setEmail(user.email)
    }
    if (user && user.profileImage) {
      setFile([{ url: user.profileImage, id: '1' }])
    }
  }, [user])

  const handleForm = (e) => {
    e.preventDefault();
    if (!firstName || !lastName || (!email && allowEdit)) {
      Toast.info('All fields are required!', 3, null, false)
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && allowEdit) {
      Toast.info('Please enter valid email!', 3, null, false)
    } else if (!/^[a-z ,.'-]+$/i.test(firstName) || !/^[a-z ,.'-]+$/i.test(lastName)) {
      Toast.info('Name should contain letters only!', 3, null, false)
    } else {
      try {
        if (updateImage && file && file.length > 0) {
          Toast.info(<Loader />, 100, null, false)
          const tempFilename = file[0].file.name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${userId}-${(new Date()).getTime()}.${fileExt}`
          const contentType = file[0].file.type
          client.query({
            query: GET_SIGNED_URLS,
            variables: { data: [{ fileName, contentType }] }
          }).then(async res => {
            new Compressor(file[0].file, {
              quality: 0.7,
              convertSize: 5000000,
              checkOrientation: file[0].file.size > 7000000 ? false : true,
              async success(image) {
                fileUpload(res.data.getSignedPutUrls.signedUrls[0], image)
                  .then(result => {
                    console.log(result)
                    client.mutate({
                      mutation: UPDATE_USER,
                      variables: {
                        phone, data: {
                          firstName, lastName, email, profileImage: res.data.getSignedPutUrls.getUrls[0]
                        }
                      },
                    });
                  }).then(() => {
                    setTimeout(() => {
                      client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
                        .then(data => {
                          dispatch({ type: 'CURRENT_USER', data: data.data.currentUser })
                          Toast.hide()
                          if (allowEdit)
                            props.history.push('/plan')
                          else
                            props.history.push('/profile')
                        }).catch((error) => {
                          if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
                            Toast.info(error.graphQLErrors[0].message, 3, null, false)
                          } else {
                            Toast.info(error, 3, null, false)
                          }
                        })
                    }, 1000)
                  })
              },
              error(err) {
                console.log(err.message);
                fileUpload(res.data.getSignedPutUrls.signedUrls[0], file[0].file)
                  .then(result => {
                    console.log(result)
                    client.mutate({
                      mutation: UPDATE_USER,
                      variables: {
                        phone, data: {
                          firstName, lastName, email, profileImage: res.data.getSignedPutUrls.getUrls[0]
                        }
                      },
                    });
                  }).then(() => {
                    setTimeout(() => {
                      client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
                        .then(data => {
                          dispatch({ type: 'CURRENT_USER', data: data.data.currentUser })
                          Toast.hide()
                          if (allowEdit)
                            props.history.push('/plan')
                          else
                            props.history.push('/profile')
                        }).catch((error) => {
                          if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
                            Toast.info(error.graphQLErrors[0].message, 3, null, false)
                          } else {
                            Toast.info(error, 3, null, false)
                          }
                        })
                    }, 1000)
                  })
              },
            });
          }).catch((error) => {
            console.log(error)
            if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
              Toast.info(error.graphQLErrors[0].message, 3, null, false)
            } else {
              Toast.info(error, 3, null, false)
            }
          })
        } else {
          Toast.info(<Loader />, 10, null, false)
          const data = { firstName, lastName, email }
          if (updateImage) data.profileImage = null
          client.mutate({
            mutation: UPDATE_USER,
            variables: { phone, data },
          }).then(() => {
            client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
              .then(data => {
                console.log("dispatch", data)
                dispatch({ type: 'CURRENT_USER', data: data.data.currentUser })
                Toast.hide()
                if (allowEdit)
                  if (data.data.currentUser.isActive)
                    props.history.push('/')
                  else
                    props.history.push('/how-it-works')
                else
                  props.history.push('/profile')
              })
          }).catch((error) => {
            if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
              Toast.info(error.graphQLErrors[0].message, 3, null, false)
            } else {
              Toast.info(error, 3, null, false)
            }
          })
        }
      }
      catch (error) {
        console.log(error)
        if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
          Toast.info(error.graphQLErrors[0].message, 3, null, false)
        } else {
          Toast.info('Something went wrong!', 3, null, false)
        }
      }
    }
  }

  const onChange = (files, type, index) => {
    setUpdateImage(true)
    setFile(files)
  }

  return (
    < WingBlank size='sm'  >
      <form >
        <List onSubmit={(e) => handleForm(e)} className='profile-form'>
          <div className='edit-picture'>
            <ImagePicker
              files={file}
              onChange={onChange}
              onImageClick={(index, fs) => console.log(index, fs)}
              selectable={file.length < 1}
              multiple={multiple}
              length='1'
            />
          </div>
          <label htmlFor='first name' style={{ marginTop: '10vh' }}>First Name</label>
          <InputItem value={firstName} onChange={(e) => setFirstName(e)} />
          <WhiteSpace style={{ backgroundColor: '#333333' }} />
          <label htmlFor='last name'>Last Name</label>
          <InputItem value={lastName} onChange={(e) => setLastName(e)} />
          <WhiteSpace style={{ backgroundColor: '#333333' }} />
          <label htmlFor='email'>Email</label>
          <InputItem
            onChange={(e) => setEmail(e)}
            value={email}
            disabled={email && !allowEdit ? true : false}
          />
        </List>
        <Flex justify="center" style={{ marginTop: '5vh' }}>
          <Button className='btn' onClick={(e) => handleForm(e)}>{allowEdit ? 'Complete Profile' : 'Update'}</Button>
        </Flex>
      </form>
    </WingBlank >
  );
}

export default withRouter(ProfileForm);