import React from 'react'
import { Route } from 'react-router-dom'
import Cookies from 'universal-cookie'
import LandingRoutes from './LandingRoutes'

const cookies = new Cookies()

function GuardedRoute(props) {
  const { component: Component, path } = props
  const authorizationToken = cookies.get("token");
  const isAuthenticated = authorizationToken ? true : false
  return (
    <Route path={path} render={(props) => {
      if (isAuthenticated !== undefined && !isAuthenticated) return <LandingRoutes />
      return <Component {...props} />
    }} />
  )
}

export default GuardedRoute;