import React, { Fragment } from 'react';
import { Button, WingBlank, Flex, } from 'antd-mobile';
import { useQuery } from '@apollo/react-hooks';
import { GET_RESTAURANT } from '../../modules/restaurant/graphql/Queries'
import CheckInDetails from './components/CheckInDetails'
import Navbar from '../../components/navbar'
import ErrorMSG from '../../components/errormsg';
import Meta from '../../components/Meta';
import Loader from '../../components/loader';
import './style/index.css'

const CheckIn = (props) => {
  const { match: { params: { id } } } = props
  // TODO: Remove useEffect
  // TODO: show proper loader on page
  // TODO: remove setRestaurant state
  // TODO: user restaurant query instead of restaurants (it will give object instead array)
  const { loading, data } = useQuery(GET_RESTAURANT, { variables: { id: id } });

  return (
    loading ?
      <Loader />
      :
      data && data.restaurant ?
        <Fragment>
          <Meta title='Checked In' description='' />
          <Navbar title='CHECKED IN'>
            <WingBlank size='sm'>
              <WingBlank size='lg' className='checkin-content' >
                <CheckInDetails restaurantData={data.restaurant} />
              </WingBlank>
              <WingBlank size='lg' >
                <Flex justify="center" style={{ marginTop: '15vh' }}>
                  <Flex.Item>
                    <Button onClick={() => { props.history.replace('checkout') }} className='btn-inverted'>Checkout &amp; Pay</Button>
                  </Flex.Item>
                </Flex>
              </WingBlank>
            </WingBlank>
          </Navbar>
        </Fragment>
        :
        <ErrorMSG />
  );
}

export default CheckIn;