import React, { Fragment } from "react";
import { WingBlank, Flex } from "antd-mobile";
import moment from "moment";
import Loader from "../../../components/loader";

const Schedule = props => {
  const timings = props.data || []
  const dayName = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday"
  };

  return (
    <Fragment>
      <Flex className='schedule-title'>
        <WingBlank size="lg">
          <div>Hours Available</div>
        </WingBlank>
      </Flex>
      <Flex className="table-block">
        {!timings || timings.length === 0 ? (
          <Loader />
        ) : (
            <table cellPadding="">
              <tbody>
                {timings.map(data => (
                  <tr className="row" key={data.day}>
                    <td className="day">{dayName[data.day]}:</td>
                    {
                      data.timingSlots.map((slot, i) => (
                        <td className={i === 0 ? "time" : ""} key={i}>
                          {slot && slot.startTime && slot.endTime
                            ? `${moment(slot.startTime).format("hh:mm A")} - ${moment(slot.endTime).format("hh:mm A")}`
                            : null}
                        </td>
                      ))
                        .reduce((p, n) => p.props.children && n.props.children ? [p, <td key={p.key + 7}>&amp;</td>, n] : (p.props.children ? [p] : n.props.children ? [n] : [<td key={p.key + 7} className='danger-text time'>Closed</td>]))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
      </Flex>
    </Fragment>
  );
};

export default Schedule;