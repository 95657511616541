import React from 'react'
import { Helmet } from 'react-helmet'

export default function ({ title, description = '' }) {

  return (
    <Helmet>
      <meta name="author" content="iKoVerk" />
      <meta charset="UTF-8" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:url" content={process.env.REACT_APP_WEB_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${process.env.REACT_APP_WEB_URL}static/media/ikoverk.888afc5b.png`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={process.env.REACT_APP_WEB_URL} />
      <meta name="twitter:image" content={`${process.env.REACT_APP_WEB_URL}static/media/ikoverk.888afc5b.png`} />
      <meta charSet="utf-8" />
      <title>{title || 'iKoVerk'}{description && ` - ${description}`} | iKoVerk</title>
      {/* {description && <meta name="description" content={description} />} */}
      <link rel="apple-touch-icon" sizes="128x128" href={`${process.env.REACT_APP_WEB_URL}favicon.ico`} />
      <link rel="icon" sizes="128x128" href={`${process.env.REACT_APP_WEB_URL}favicon.ico`} />
      <meta name="title" content={title || 'iKoVerk'} />
      <meta property="og:title" content={title || 'iKoVerk'} />
      <meta name="twitter:title" content={title || 'iKoVerk'} />
      <meta name="description" content="" />
      <meta property="og:description" content="" />
      <meta name="twitter:description" content="" />
      <meta name="keywords" content="" />
    </Helmet>
  )
}