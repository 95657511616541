import React, { useEffect, useContext, Fragment } from 'react';
import { Icon, WhiteSpace, WingBlank, Flex } from 'antd-mobile';
import { AppContext } from '../AppContext';
import { client } from '../apollo';
import { GET_CURRENT_USER } from '../modules/profile/graphql/Queries';
import Meta from './../components/Meta';

const Payment = (props) => {
  const { dispatch } = useContext(AppContext)
  useEffect(() => {
    client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
      .then(data => {
        dispatch({ type: 'CURRENT_USER', data: data.data.currentUser })
        setTimeout(() => {
          props.history.replace("/")
        }, 2000)
      })
      .catch(error => {
        console.log({ error })
      })
  }, [dispatch, props.history])

  const payStatus = 1
  const drawIcon = status => {
    if (status) {
      return <Icon type='check' size='lg' style={{ backgroundColor: '#aeca36' }} />
    }
    return <Icon type='cross' className='red' size='lg' style={{ backgroundColor: '#D34E4E' }} />
  }

  return (
    <Fragment>
      <Meta title='Payment' description='' />
      <WingBlank className='flex-center' style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <Flex justify='center' >
          <div className='pay-status'>
            {drawIcon(payStatus)}
          </div>
        </Flex>
        <WhiteSpace size='lg' />
        <Flex justify='center'>
          <h2 style={{ color: 'white' }}>Payment {payStatus ? 'Done Successfully!' : 'Failed!'}</h2>
        </Flex>
      </WingBlank>
    </Fragment>
  );
}

export default Payment;