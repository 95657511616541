import gql from 'graphql-tag'

export const GET_CURRENT_USER = gql`
query currentUser{
  currentUser {
    id
    email
    firstName
    lastName
    role
    profileImage
    isActive
    phone
    hasUpcomingPlan
    referralCode
    userSubscription {
      id
      plan{
        id
        name
        points
        validity
      }
      startTime
      endTime
      points
      status
      plans
      lastOverDueAmount
      lastOverDueDate
    }
    planStatus
    points
    topUp
  }
}
`;

export const GET_SIGNED_URLS = gql`
query getSignedPutUrls($data: [SignedUrlDataInput]){
  getSignedPutUrls(data: $data){
  fileNames
  getUrls
  signedUrls
  }
}
`;

export const USER_PROFILE_DETAIL = gql`
query userHistories($id: ID!){
  userHistories(where: {user: {id: $id}})
}`;