import gql from 'graphql-tag';

export const CREATE_TOPUP = gql`
  mutation createTopUp($data : TopUpCreateInput!){
    createTopUp(data: $data){
      id
    }
  }`;

export const MAKE_PAYMENT = gql`
mutation createPayment($data: PaymentCreateInput!){
  createPayment(data: $data){
    id
  }
}
`;