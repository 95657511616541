import React, { Fragment } from 'react'
import { WingBlank, Flex, WhiteSpace } from 'antd-mobile'
import Meta from './../components/Meta'
import Navbar from './navbar'

export default function Page404() {
  return (
    <Fragment>
      <Meta title='404' description='' />
      <Navbar title='IKOVERK'>
        <WingBlank className='vertical-center'>
          <Flex justify='center'>
            <div className="title-404" style={{ fontSize: '3em', fontWeight: '600' }}>404</div>
          </Flex>
          <WhiteSpace size='lg' />
          <Flex justify='center'>
            <div className="subtitle-404" style={{ fontSize: '1.5em', fontWeight: '500' }}>PAGE NOT FOUND</div>
          </Flex>
          <WhiteSpace />
          <WhiteSpace />
          <Flex justify='center'>
            <div className="description-404" style={{ fontSize: '1.1em', fontWeight: '400', textAlign: 'center' }}>
              The page you are looking for was moved, removed, renamed or might never existed.
      </div>
          </Flex>
        </WingBlank>
      </Navbar>
    </Fragment>
  )
}