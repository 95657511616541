import gql from 'graphql-tag';

export const LOGIN = gql`
mutation login($phone: String!, $password: String!) {
  login(data: { phone: $phone, password: $password })
  @rest(path: "login", method: "POST", bodyKey: "data") {
    message
  }
}
`
export const CREATE_USER = gql`
mutation createUser($data: UserCreateWithoutPasswordInput){
  createUser(data: $data) {
    id
  }
}
`;

export const LOGOUT = gql`
mutation logout {
  logout @rest(path: "logout", method: "GET") {
    message
  }
}
`
export const CREATE_REFERRAL = gql`
mutation createRefferal($userId:ID!, $referralId:ID!){
  createReferral(
    data: {
      referTo: { connect: { id: $userId } }
      referFrom: { connect: { id: $referralId } }
    }
  ) {
    id
  }
}
`;