export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest()
      xhr.open("PUT", signedUrl)
      xhr.setRequestHeader('Content-Type', image.type);
      xhr.setRequestHeader('x-amz-acl', 'public-read');
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          resolve(xhr.response)
        }
      })
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0
          percentComplete = Math.ceil((e.loaded / e.total) * 100)
          onUploadProgress(percentComplete)
        }
      }
      xhr.send(image)
    })
  }
  catch (e) {
    console.error(e)
  }
}