import React, { Fragment, useContext } from 'react';
import { withRouter } from 'react-router-dom'
import { WingBlank, Flex, } from 'antd-mobile';
import { AppContext } from '../../../AppContext'
import Navbar from '../../../components/navbar'
import Meta from '../../../components/Meta';
import ProfileForm from '../components/ProfileForm'

const EditProfile = (props) => {
  // TODO: unnecessary blank lines, verify in all file
  const { state } = useContext(AppContext)
  const user = state.currentUser
  const allowEdit = props.match.path.split('/').pop() === 'new' ? true : false;

  return (
    <Fragment>
      <Meta title='Edit Profile' />
      {
        !allowEdit ?
          (<Navbar title='EDIT PROFILE' backButton={true}>
            <WingBlank>
              <div>
                <ProfileForm userData={user} allowEdit={allowEdit} />
              </div>
            </WingBlank>
          </Navbar>)
          :
          (
            <WingBlank>
              <Flex justify='center'>
                <div className='profile-header'>Complete Profile</div>
              </Flex>
              <div>
                <ProfileForm allowEdit={allowEdit} />
              </div>
            </WingBlank>
          )
      }
    </Fragment>
  );
}

export default withRouter(EditProfile);