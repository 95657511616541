//Contains List of all Restaurants
import React, { Fragment, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_RESTAURANTS_LIST } from './graphql/Queries'
import { AppContext } from '../../AppContext'
import RestaurantCard from './components/RestaurantCard'
import Loader from '../../components/loader'
import ErrorMSG from '../../components/errormsg'

const RestaurantList = (props) => {
  const { state } = useContext(AppContext)
  const user = state.currentUser
  const hasUpcomingPlan = user ? user.hasUpcomingPlan : false
  const userPoints = user && user.points ? hasUpcomingPlan ? (user.points + user.userSubscription.lastOverDueAmount) : user.points : 0
  const userTopUpPoints = user && user.topUp ? user.topUp : 0
  const { loading, data } = useQuery(GET_RESTAURANTS_LIST, { variables: { isDisabled: false }, fetchPolicy: 'network-only' });

  return (
    loading ?
      <Loader /> :
      data && data.restaurants ?
        <Fragment>
          {data && data.restaurants && data.restaurants.length > 0 &&
            <Fragment>
              <h3 style={{ color: '#eee', fontSize: '1.3em', fontWeight: '500', margin: '15px 0', marginTop: '1.5em' }}>Restaurant Availabilities</h3>
              {data.restaurants.map(restaurant => <RestaurantCard data={restaurant} key={restaurant.id} isDisabled={restaurant.minCheckinPoints > userPoints + userTopUpPoints} />)}
            </Fragment>
          }
        </Fragment>
        :
        <ErrorMSG />
  );
}

export default RestaurantList;