import gql from 'graphql-tag';

export const GET_TRANSACTION = gql`
query transaction($id:ID!){
  transaction(where:{id:$id}){
    id
    user{
      id
      firstName
      lastName
    }
    restaurant{
      name
      address
    }
    amount
    createdAt
  }
}
`; 