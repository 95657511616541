import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Button, WingBlank, Modal, Flex, List, InputItem, Toast, WhiteSpace } from 'antd-mobile';
import { useQuery } from '@apollo/react-hooks';
import { AppContext } from '../../AppContext'
import { client } from '../../apollo'
import { CHECK_OUT, } from './graphql/Mutations'
import { CREATE_TRANSACTION } from './graphql/Mutations'
import { GET_CHECKED_IN } from '../checkin/qraphql/Queries'
import { GET_RESTAURANT } from '../../modules/restaurant/graphql/Queries'
import TopupModal from '../topup/Modal'
import Loader from '../../components/loader';
import Navbar from '../../components/navbar'
import ErrorMSG from '../../components/errormsg';
import Meta from '../../components/Meta';
import './style/index.css'
import placeHolderLogo from '../../assets/restaurant-placeholder.jpg';

const alert = Modal.alert

const CheckOut = (props) => {
  const { state } = useContext(AppContext)
  const [checkinLoading, setCheckinLoading] = useState(true)
  const user = state.currentUser
  const userSubscription = user.userSubscription
  const currentPoints = user.hasUpcomingPlan ? (user.points + userSubscription.lastOverDueAmount) : user.points
  const { match: { params: { id } } } = props
  const [inputPoints, setInputPoints] = useState(0);
  const [showModal, setShowModal] = useState(false)
  const { loading: resLoad, data: resData } = useQuery(GET_RESTAURANT, { variables: { id: id }, fetchPolicy: 'network-only' });
  const minCheckinPoints = resData && resData.restaurant.minCheckinPoints ? resData.restaurant.minCheckinPoints : 0
  // TODO: Remove useEffect
  // TODO: show proper loader on page
  // TODO: remove setRestaurant state
  // TODO: use restaurant query instead of restaurants (it will give object instead array)
  // TODO: check in all files show proper loader on every page using useQuery's loader variable
  // TODO: check in all files remove useState(if not necessary to set query data)
  // TODO: follow below points for every main query page
  // 1) show loader while loading
  // 2) if error show error or specific message
  // 3) if not loading and not error show your data related components

  useEffect(() => {
    client.query({
      query: GET_CHECKED_IN,
      variables: { id: user.id },
      fetchPolicy: 'network-only'
    }).then(res => {
      if (res && res.data && res.data.checkIns && res.data.checkIns.length === 0) {
        props.history.goBack()
      } else {
        setCheckinLoading(false)
      }
    }).catch((error) => {
      console.log(error)
      if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
        Toast.info(error.graphQLErrors[0].message, 3, null, false)
      } else {
        Toast.info('Something went wrong!', 3, null, false)
      }
    });
  }, [user.id, props.history])

  const onPointsChange = (e) => {
    setInputPoints(parseInt(e) ? Math.abs(parseInt(e)) : 0)
  }

  const pay = () => {
    try {
      Toast.info(<Loader />, 30, null, true);
      const data = {
        restaurant: { connect: { id: id } },
        user: {
          connect: {
            id: user.id
          }
        },
        subscription: { connect: { id: user.userSubscription.id } },
        amount: parseFloat(inputPoints > minCheckinPoints ? inputPoints : minCheckinPoints),
      }
      client.mutate({
        mutation: CREATE_TRANSACTION,
        variables: { data: data }
      })
        .then((result) => {
          return client.mutate({
            mutation: CHECK_OUT,
            variables: {
              data: {
                user: { connect: { id: user.id } },
                transaction: { connect: { id: result.data.createTransaction.id } }
              },
              userId: user.id
            }
          })
        })
        .then(res => { props.history.replace(`/transaction/${res.data.updateCheckInCheckOut.transaction.id}`); Toast.hide(); })
        .catch(error => {
          console.log(error)
          if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
            Toast.fail(error.graphQLErrors[0].message, 3, null, false)
          } else {
            Toast.fail('Something went wrong!', 3, null, false)
          }
        })
    } catch (error) {
      console.log(error)
      Toast.hide()
      if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
        Toast.info(error.graphQLErrors[0].message, 3, null, false)
      } else {
        Toast.info('Something went wrong!', 3, null, false)
      }
    }
    // TODO: don't make blank space inside function without any use
  }

  const doCheckOut = () => {
    try {
      Toast.info(<Loader />, 30, null, true);

      return client.mutate({
        mutation: CHECK_OUT,
        variables: {
          data: {
            user: { connect: { id: user.id } },
          },
          userId: user.id
        }
      })
        .then(res => { Toast.success('Checked out successfully!'); props.history.replace("/"); })
        .catch(error => {
          console.log(error)
          if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
            Toast.fail(error.graphQLErrors[0].message, 3, null, false)
          } else {
            Toast.fail('Something went wrong!', 3, null, false)
          }
        })
    } catch (error) {
      console.log(error)
      Toast.hide()
      if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
        Toast.info(error.graphQLErrors[0].message, 3, null, false)
      } else {
        Toast.info('Something went wrong!', 3, null, false)
      }
    }
  }
  const isDisabled = minCheckinPoints ? inputPoints ? inputPoints > (currentPoints + user.topUp) : minCheckinPoints > (currentPoints + user.topUp) : inputPoints > (currentPoints + user.topUp)

  return (
    checkinLoading || resLoad ?
      <Loader />
      :
      resData && resData.restaurant ?
        <Fragment>
          <Meta title='Check Out' />
          <Navbar title='CHECK OUT' backButton={true}>
            <WingBlank size='sm' className={`${isDisabled ? 'isDisabled' : ''}`}>
              <WingBlank size='lg' className={`checkout-content`}>
                <Flex justify='center' className='title' >
                  <div className='img-div'>
                    <img alt='logo' src={resData.restaurant.logo || placeHolderLogo} style={{ height: '50px', width: '50px' }} />
                  </div>
                  <div className='details'>
                    <div className='name'>
                      {resData.restaurant.name}
                    </div>
                    <div className='location'>
                      {resData.restaurant.address}
                    </div>
                  </div>
                </Flex>
                <Flex className='points-header'>
                  <div>How many Funds do you wish to use to pay the Restaurant Bill? </div>
                </Flex>
                <Flex justify='center' className='input-block'>
                  <WingBlank size='lg'>
                    <WingBlank size='lg'>
                      <Flex className='current-balance' justify='between'>
                        <Flex.Item>
                          <div>Plan Funds: <span className='points'>{Math.round(currentPoints)}</span></div>
                        </Flex.Item>
                        {
                          user.topUp ?
                            <Flex.Item>
                              <div className='align-right'>Topup Funds: <span className='points'>{Math.round(user.topUp)}</span></div>
                            </Flex.Item> : null
                        }
                      </Flex>
                      <List className='points-input-list'>
                        <InputItem
                          className='pay-input'
                          type='digit'
                          value={inputPoints}
                          placeholder="Enter bill amount"
                          // extra="P"
                          onChange={(e) => onPointsChange(e)}
                          onKeyUp={(e) => {
                            if ((e.keyCode === 13 || e.which === 13) && e.target.value.length !== '') {
                              e.target.blur()
                            }
                          }}
                        >{`   `}Funds <span className='vertical-line'>|</span> </InputItem>
                      </List>
                    </WingBlank>
                  </WingBlank>
                </Flex>
                <Flex className='points-header'>
                  <div>Funds Summary</div>
                </Flex>
                <WhiteSpace />
                <WhiteSpace />
                <WingBlank>
                  <WingBlank className='summary'>
                    <Flex justify='between' className='points-flex'>
                      <Flex.Item>Funds:</Flex.Item>
                      <Flex.Item className='align-right'>
                        {inputPoints ? inputPoints : 0}
                      </Flex.Item>
                    </Flex>
                    <WhiteSpace />
                    <WhiteSpace />
                    <Flex justify='between' className='checkin-fee-flex'>
                      <Flex.Item>Check-in Fees:</Flex.Item>
                      <Flex.Item className='align-right'>
                        {inputPoints < minCheckinPoints ? (minCheckinPoints - inputPoints) : 0}
                        {/* <Fragment><span style={{ color: '#FF7171', textDecoration: 'line-through' }}>50</span> 0</Fragment> */}
                      </Flex.Item>
                    </Flex>
                    <WhiteSpace size='sm' />
                    <WhiteSpace size='sm' style={{ borderBottom: '1px solid #CCCCCC' }} />
                    <WhiteSpace size='sm' />
                    <WhiteSpace size='sm' />
                    <Flex justify='between' className='total-flex'>
                      <Flex.Item>
                        Total:
                </Flex.Item>
                      <Flex.Item className='align-right'>
                        {inputPoints ? inputPoints < minCheckinPoints ? minCheckinPoints : inputPoints : minCheckinPoints}
                      </Flex.Item>
                    </Flex>
                    <WhiteSpace size='sm' />
                    <WhiteSpace size='sm' style={{ borderBottom: '1px dashed #CCCCCC' }} />
                    <WhiteSpace size='sm' />
                    <WhiteSpace size='sm' />
                    <Fragment>
                      {
                        currentPoints && (inputPoints || minCheckinPoints) ?
                          <Flex justify='between' className='deduction-summary'>
                            <Flex.Item style={{ flex: 3 }}>Paying from Plan Funds:</Flex.Item>
                            <Flex.Item className='align-right fw-500'>
                              {minCheckinPoints > currentPoints ? currentPoints : inputPoints > currentPoints ? currentPoints : inputPoints < minCheckinPoints ? minCheckinPoints : inputPoints}
                            </Flex.Item>
                          </Flex>
                          :
                          null
                      }
                      {
                        user.topUp && (currentPoints <= minCheckinPoints || inputPoints > currentPoints) && (inputPoints || minCheckinPoints) ?
                          <Fragment>
                            <WhiteSpace />
                            <Flex justify='between' className='deduction-summary'>
                              <Flex.Item style={{ flex: 3 }}>Paying from Topup:</Flex.Item>
                              <Flex.Item className='align-right fw-500'>
                                {user.topUp < (inputPoints - currentPoints) ? user.topUp : currentPoints <= minCheckinPoints && !inputPoints ? minCheckinPoints - currentPoints : inputPoints >= minCheckinPoints ? (inputPoints - currentPoints) : minCheckinPoints - currentPoints}
                              </Flex.Item>
                            </Flex>
                          </Fragment> : null
                      }
                    </Fragment>
                  </WingBlank>
                </WingBlank>
                {
                  isDisabled &&
                  <Flex justify='between' className="top-up-wrapper">
                    <Flex.Item style={{ flex: 3 }}>
                      Insufficient Funds
                </Flex.Item>
                    <Flex.Item className='align-right'>
                      <Button onClick={() => setShowModal(true)}>{`TOPUP`}</Button>
                    </Flex.Item>
                  </Flex>
                }
              </WingBlank>
              <WhiteSpace size='xl' /><WhiteSpace size='xl' />
              <WhiteSpace size='xl' /><WhiteSpace size='lg' />
              <WingBlank>
                <Flex justify="center">
                  <Button
                    onClick={() => {
                      isDisabled ?
                        alert('', 'Insufficient Funds to check-in any Restaurant', [
                          { text: 'Cancel' },
                          { text: 'ADD', onPress: () => setShowModal(true) },
                        ])
                        :
                        alert(minCheckinPoints || inputPoints ? 'Pay' : 'Checkout', minCheckinPoints || inputPoints ? <>Are you sure you want to spend <b>{inputPoints > minCheckinPoints ? inputPoints : minCheckinPoints}</b> funds?</> : `Are you sure you want to checkout?`, [
                          { text: 'Cancel' },
                          { text: 'Yes', onPress: minCheckinPoints || inputPoints ? () => pay() : () => doCheckOut() },
                        ])
                    }}
                    className='btn btn-floating'>{minCheckinPoints || inputPoints ? `Pay ${inputPoints ? inputPoints > minCheckinPoints ? inputPoints : minCheckinPoints : minCheckinPoints} Funds` : `CHECKOUT`}
                  </Button>
                </Flex>
              </WingBlank>
            </WingBlank>
          </Navbar>
          <TopupModal visible={showModal} setShowModal={setShowModal} />
        </Fragment> :
        <ErrorMSG />
  );
}

export default CheckOut;
