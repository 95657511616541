import React, { Fragment } from 'react';
import moment from 'moment'
import Timer from 'react-compound-timer'
import '../style/index.css'

const CheckInTimer = (props) => {

  return (
    <Fragment>
      <Timer initialTime={moment().diff(props.time, 'milliseconds') + 1000}>
        <div className='time'><Timer.Hours formatValue={value => Math.abs(value).toString().length === 1 ? `0${Math.abs(value)}` : Math.abs(value)} />:<Timer.Minutes formatValue={value => Math.abs(value).toString().length === 1 ? `0${Math.abs(value)}` : Math.abs(value)} /></div>
        <div className='seconds'>:<Timer.Seconds formatValue={value => Math.abs(value).toString().length === 1 ? `0${Math.abs(value)}` : Math.abs(value)} /> </div>
      </Timer>
    </Fragment>
  )
}

export default CheckInTimer