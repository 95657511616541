import React from 'react';
import { Flex } from 'antd-mobile';
import moment from 'moment';
import titleCase from '../../../components/titleCase'
import placeHolderLogo from '../../../assets/restaurant-placeholder.jpg';

export default function LogHistory(props) {
  const { data } = props
  return (
    data ? data.map((data, key) => createHistory(data, key)) : null
  )
}

const createHistory = (data, key) => {
  if (data.type === 'TRANSACTION') {
    return (
      <Flex justify='inbetween' className='history-row' key={key} /*onClick={()=>{props.history.push(`/transaction/${data.id}`)}}*/>
        <Flex.Item className='left-content plan-content' >
          <img src={data.logo || placeHolderLogo} alt='logo' height='40px' width='40px' style={{ borderRadius: '5px' }} />
          <div style={{ paddingLeft: '10px', alignSelf: 'center' }}>
            <div className='name'>
              {data.name}
            </div>
            <div className='plan-status'>
              {data.description}
            </div>
          </div>
        </Flex.Item >
        <Flex.Item className='right-content' >
          <div className='amount red'>
            {`- ${data.points} Funds`}
          </div>
          <div className='date-time'>
            {`On ${moment(data.createdAt).format('DD/MM/YYYY [at] hh:mm A')}`}
          </div>
          <div className='duration'>
            {`Duration: ${parseInt(data.duration / 60) ? `${parseInt(data.duration / 60)} hours` : ''}${data.duration % 60} minutes`}
          </div>
        </Flex.Item>
      </Flex>
    )
  } else if (data.type === 'PLAN') {
    return (
      <Flex justify='inbetween' className='history-row' key={key}>
        <Flex.Item className='left-content'>
          <svg width="40" height="31" viewBox="-5 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.4792 6.89585C24.767 6.89585 25 6.66289 25 6.375V2.20835C25 1.05933 24.0657 0.125 22.9167 0.125H2.08335C0.934326 0.125 0 1.05933 0 2.20835V6.375C0 6.66289 0.232959 6.89585 0.52085 6.89585C1.95669 6.89585 3.125 8.06416 3.125 9.5C3.125 10.9358 1.95669 12.1042 0.52085 12.1042C0.232959 12.1042 0 12.3371 0 12.625V16.7917C0 17.9407 0.934326 18.875 2.08335 18.875H22.9167C24.0657 18.875 25 17.9407 25 16.7917V12.625C25 12.3371 24.767 12.1042 24.4792 12.1042C23.0433 12.1042 21.875 10.9358 21.875 9.5C21.875 8.06416 23.0433 6.89585 24.4792 6.89585ZM20.8333 9.5C20.8333 11.3336 22.1939 12.8554 23.9583 13.1087V16.7917C23.9583 17.3659 23.4909 17.8333 22.9167 17.8333H2.08335C1.50913 17.8333 1.0417 17.3659 1.0417 16.7917V13.1087C2.8061 12.8554 4.1667 11.3336 4.1667 9.5C4.1667 7.66641 2.8061 6.14458 1.0417 5.89131V2.20835C1.0417 1.63413 1.50913 1.1667 2.08335 1.1667H22.9167C23.4909 1.1667 23.9583 1.63413 23.9583 2.20835V5.89131C22.1939 6.14458 20.8333 7.66641 20.8333 9.5Z" fill="white" />
            <path d="M6.77085 2.07812C6.48296 2.07812 6.25 2.31108 6.25 2.59897V3.77085C6.25 4.05874 6.48296 4.2917 6.77085 4.2917C7.05874 4.2917 7.2917 4.05874 7.2917 3.77085V2.59897C7.29165 2.31108 7.05874 2.07812 6.77085 2.07812Z" fill="white" />
            <path d="M6.77085 6.375C6.48296 6.375 6.25 6.60796 6.25 6.89585V7.9375C6.25 8.22539 6.48296 8.45835 6.77085 8.45835C7.05874 8.45835 7.2917 8.22539 7.2917 7.9375V6.89585C7.29165 6.60796 7.05874 6.375 6.77085 6.375Z" fill="white" />
            <path d="M6.77085 10.5417C6.48296 10.5417 6.25 10.7747 6.25 11.0626V12.1042C6.25 12.3921 6.48296 12.6251 6.77085 12.6251C7.05874 12.6251 7.2917 12.3921 7.2917 12.1042V11.0626C7.29165 10.7747 7.05874 10.5417 6.77085 10.5417Z" fill="white" />
            <path d="M6.77085 14.7083C6.48296 14.7083 6.25 14.9412 6.25 15.2291V16.2708C6.25 16.5586 6.48296 16.7916 6.77085 16.7916C7.05874 16.7916 7.2917 16.5586 7.2917 16.2708V15.2291C7.29165 14.9412 7.05874 14.7083 6.77085 14.7083Z" fill="white" />
            <path d="M17.4764 4.37916C17.2353 4.21945 16.9134 4.28405 16.7541 4.5236L10.5041 13.8986C10.3444 14.1381 10.409 14.4611 10.6486 14.6209C10.7376 14.6798 10.8378 14.7084 10.937 14.7084C11.1053 14.7084 11.2706 14.627 11.3708 14.4764L17.6208 5.10143C17.7805 4.86183 17.7159 4.53888 17.4764 4.37916Z" fill="white" />
            <path d="M13.5417 6.8959C13.5417 5.46006 12.6073 4.29175 11.4583 4.29175C10.3093 4.29175 9.375 5.46001 9.375 6.8959C9.375 8.33179 10.3093 9.50005 11.4583 9.50005C12.6074 9.50005 13.5417 8.33174 13.5417 6.8959ZM10.4167 6.8959C10.4167 6.04902 10.8937 5.3334 11.4583 5.3334C12.0229 5.3334 12.5 6.04902 12.5 6.8959C12.5 7.74277 12.0229 8.4584 11.4583 8.4584C10.8937 8.4584 10.4167 7.74272 10.4167 6.8959Z" fill="white" />
            <path d="M16.6666 9.5C15.5177 9.5 14.5833 10.6683 14.5833 12.1042C14.5833 13.54 15.5176 14.7083 16.6666 14.7083C17.8156 14.7083 18.75 13.54 18.75 12.1042C18.75 10.6683 17.8157 9.5 16.6666 9.5ZM16.6666 13.6667C16.102 13.6667 15.625 12.951 15.625 12.1042C15.625 11.2573 16.1021 10.5417 16.6666 10.5417C17.2312 10.5417 17.7083 11.2573 17.7083 12.1042C17.7083 12.951 17.2312 13.6667 16.6666 13.6667Z" fill="white" />
          </svg>
          <div style={{ paddingLeft: '10px', alignSelf: 'center' }}>
            <div className='name'>
              {data.name}
            </div>
            <div className={`plan-status ${data.description === 'Plan Subscribe' ? 'green' : 'red'}`}>
              {data.description}
            </div>
          </div>
        </Flex.Item>
        <Flex.Item className='right-content'>
          <div className={`amount ${data.description === 'Plan Subscribe' ? 'green' : 'red'}`}>
            {`${data.description === 'Plan Subscribe' ? '+' : '-'} ${data.points} Funds`}
          </div>
          <div className='date-time'>
            {`On ${moment(data.createdAt).format('DD/MM/YYYY [at] hh:mm A')}`}
          </div>
        </Flex.Item>
      </Flex>
    )
  }
  else if (data.type === 'TOPUP') {
    return (
      <Flex justify='inbetween' className='history-row' key={key}>
        <Flex.Item className='left-content'>
          <svg width="40" height="31" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7693 8.10547L11.2507 7.93135C12.4068 7.51304 13.1836 6.40566 13.1836 5.17578C13.1836 3.56035 11.8693 2.24609 10.2539 2.24609C9.02397 2.24609 7.9166 3.0229 7.49834 4.17905L6.5918 6.68491L5.68525 4.17905C5.26699 3.0229 4.15962 2.24609 2.92969 2.24609C1.31426 2.24609 0 3.56035 0 5.17578C0 6.40566 0.776758 7.51304 1.93296 7.93135L2.41431 8.10547H0V22.7539H25V8.10547H10.7693ZM8.87583 4.67734C9.08496 4.09932 9.63872 3.71094 10.2539 3.71094C11.0616 3.71094 11.7188 4.36807 11.7188 5.17578C11.7188 5.79092 11.3304 6.34473 10.7523 6.55386L7.81216 7.61753L8.87583 4.67734ZM1.46484 5.17578C1.46484 4.36807 2.12197 3.71094 2.92969 3.71094C3.54487 3.71094 4.09863 4.09932 4.30776 4.67739L5.37144 7.61753L2.4313 6.55386C1.85322 6.34473 1.46484 5.79092 1.46484 5.17578ZM23.5352 21.2891H7.32422V19.8242H5.85938V21.2891H1.46484V9.57031H5.22324L3.05273 12.8261L4.27153 13.6387L5.85938 11.2569V13.9648H7.32422V11.2569L8.91211 13.6387L10.1309 12.8261L7.96035 9.57031H23.5352V21.2891Z" fill="white" />
            <path d="M13.916 15.4297C15.1276 15.4297 16.1133 14.444 16.1133 13.2324C16.1133 12.0208 15.1276 11.0352 13.916 11.0352C12.7044 11.0352 11.7188 12.0208 11.7188 13.2324C11.7188 14.444 12.7044 15.4297 13.916 15.4297ZM13.916 12.5C14.3199 12.5 14.6484 12.8286 14.6484 13.2324C14.6484 13.6363 14.3199 13.9648 13.916 13.9648C13.5122 13.9648 13.1836 13.6363 13.1836 13.2324C13.1836 12.8286 13.5122 12.5 13.916 12.5Z" fill="white" />
            <path d="M17.5781 17.627C17.5781 18.8385 18.5638 19.8242 19.7754 19.8242C20.987 19.8242 21.9727 18.8385 21.9727 17.627C21.9727 16.4154 20.987 15.4297 19.7754 15.4297C18.5638 15.4297 17.5781 16.4154 17.5781 17.627ZM20.5078 17.627C20.5078 18.0308 20.1792 18.3594 19.7754 18.3594C19.3715 18.3594 19.043 18.0308 19.043 17.627C19.043 17.2231 19.3715 16.8945 19.7754 16.8945C20.1792 16.8945 20.5078 17.2231 20.5078 17.627Z" fill="white" />
            <path d="M13.3498 18.6372L19.212 11.3118L20.3561 12.2273L14.4938 19.5527L13.3498 18.6372Z" fill="white" />
            <path d="M5.85938 15.4297H7.32422V18.3594H5.85938V15.4297Z" fill="white" />
          </svg>
          <div style={{ paddingLeft: '10px', alignSelf: 'center' }}>
            <div className='name topup'>
              TOPUP
            </div>
          </div>
        </Flex.Item>
        <Flex.Item className='right-content'>
          <div className='amount green'>
            {`+ ${data.points} Funds`}
          </div>
          <div className='date-time'>
            {`On ${moment(data.createdAt).format('DD/MM/YYYY [at] hh:mm A')}`}
          </div>
        </Flex.Item>
      </Flex>
    )
  }
  else if (data.type === 'REFUND') {
    return (
      <Flex justify='inbetween' className='history-row' key={key}>
        <Flex.Item className='left-content'>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" width="40px" height="35px"><g><g>
            <g>
              <path d="M256,79.62c-97.257,0-176.38,79.123-176.38,176.38S158.743,432.38,256,432.38S432.38,353.257,432.38,256    S353.257,79.62,256,79.62z M256,415.793c-88.11,0-159.793-71.682-159.793-159.793S167.89,96.207,256,96.207    S415.793,167.89,415.793,256S344.11,415.793,256,415.793z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
            </g>
          </g><g>
              <g>
                <path d="M264.294,249.668v-64.661c27.098,2.178,45.339,14.785,45.339,26.207c0,4.58,3.713,8.294,8.294,8.294    c4.58,0,8.294-3.713,8.294-8.294c0-22.456-26.601-40.384-61.927-42.832v-9.695c0-4.58-3.713-8.294-8.294-8.294    c-4.58,0-8.294,3.713-8.294,8.294v9.695c-35.326,2.448-61.927,20.376-61.927,42.832c0,30.084,28.605,41.823,61.927,51.118v64.661    c-27.098-2.179-45.339-14.785-45.339-26.207c0-4.58-3.713-8.294-8.294-8.294c-4.58,0-8.294,3.713-8.294,8.294    c0,22.456,26.601,40.384,61.927,42.832v9.695c0,4.58,3.713,8.294,8.294,8.294c4.58,0,8.294-3.713,8.294-8.294v-9.695    c35.326-2.448,61.927-20.376,61.927-42.832C326.22,270.702,297.616,258.964,264.294,249.668z M247.706,245.057    c-33.638-9.939-45.339-18.966-45.339-33.843c0-11.422,18.241-24.029,45.339-26.207V245.057z M264.294,326.993v-60.05    c33.638,9.939,45.339,18.966,45.339,33.843C309.633,312.208,291.392,324.815,264.294,326.993z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M437.019,74.981C388.668,26.628,324.38,0,256,0C182.385,0,113.65,30.975,65.041,85.5l4.543-31.8    c0.647-4.535-2.504-8.735-7.038-9.383c-4.536-0.643-8.735,2.504-9.383,7.037l-8.847,61.927c-0.406,2.845,0.69,5.698,2.896,7.541    c1.514,1.263,3.4,1.926,5.316,1.926c0.878,0,1.764-0.139,2.622-0.426l53.08-17.693c4.346-1.449,6.694-6.145,5.246-10.491    c-1.45-4.346-6.145-6.696-10.491-5.246l-26.668,8.89C121.837,46.021,186.602,16.587,256,16.587    c63.949,0,124.071,24.903,169.291,70.122c45.219,45.22,70.122,105.341,70.122,169.291s-24.903,124.071-70.122,169.291    c-45.22,45.219-105.341,70.122-169.291,70.122c-4.58,0-8.294,3.713-8.294,8.294c0,4.58,3.713,8.294,8.294,8.294    c68.38,0,132.668-26.628,181.019-74.981S512,324.38,512,256S485.372,123.332,437.019,74.981z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M221.789,492.987c-2.82-0.404-5.66-0.861-8.441-1.361c-4.51-0.813-8.82,2.186-9.631,6.695    c-0.811,4.508,2.186,8.82,6.695,9.631c2.976,0.535,6.013,1.025,9.028,1.456c0.398,0.057,0.794,0.085,1.185,0.085    c4.06,0,7.608-2.985,8.2-7.119C229.472,497.838,226.323,493.637,221.789,492.987z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M181.254,483.516c-5.221-1.714-10.451-3.636-15.546-5.712c-4.24-1.726-9.081,0.308-10.811,4.552    c-1.728,4.242,0.31,9.082,4.552,10.811c5.451,2.221,11.046,4.276,16.632,6.11c0.859,0.282,1.731,0.416,2.589,0.416    c3.486,0,6.731-2.216,7.879-5.709C187.976,489.63,185.606,484.945,181.254,483.516z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M22.092,307.29c-1.171-5.368-2.169-10.848-2.966-16.288c-0.665-4.533-4.873-7.664-9.407-7.004    c-4.533,0.664-7.669,4.876-7.004,9.407c0.853,5.818,1.919,11.679,3.172,17.42c0.847,3.88,4.281,6.528,8.095,6.528    c0.586,0,1.181-0.062,1.776-0.192C20.232,316.184,23.069,311.765,22.092,307.29z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M19.299,182.287c-4.413-1.212-8.98,1.387-10.192,5.805c-1.556,5.667-2.932,11.466-4.09,17.234    c-0.902,4.491,2.007,8.863,6.498,9.765c0.552,0.111,1.1,0.164,1.642,0.164c3.869,0,7.332-2.723,8.122-6.662    c1.083-5.392,2.37-10.814,3.824-16.113C26.314,188.063,23.717,183.499,19.299,182.287z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M37.658,354.358c-2.26-5.008-4.372-10.16-6.278-15.312c-1.589-4.296-6.361-6.491-10.656-4.901    c-4.296,1.589-6.49,6.36-4.901,10.656c2.038,5.51,4.297,11.021,6.715,16.38c1.384,3.069,4.403,4.884,7.565,4.884    c1.14,0,2.299-0.237,3.406-0.736C37.685,363.445,39.541,358.533,37.658,354.358z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M9.241,232.649c-4.578-0.278-8.502,3.209-8.776,7.782C0.157,245.582,0,250.82,0,256.007c0,0.707,0.003,1.413,0.009,2.119    c0.036,4.558,3.742,8.228,8.293,8.228c0.021,0,0.044,0,0.066,0c4.58-0.036,8.264-3.779,8.229-8.359    c-0.005-0.667-0.009-1.334-0.009-1.995c0-4.85,0.147-9.753,0.436-14.575C17.299,236.853,13.814,232.924,9.241,232.649z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M135.995,463.212c-4.75-2.757-9.47-5.719-14.03-8.805c-3.794-2.567-8.949-1.574-11.517,2.219    c-2.568,3.794-1.574,8.949,2.219,11.517c4.874,3.3,9.922,6.467,15.001,9.415c1.31,0.761,2.741,1.122,4.156,1.122    c2.86,0,5.642-1.481,7.181-4.133C141.305,470.588,139.957,465.512,135.995,463.212z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M62.633,397.197c-3.245-4.437-6.376-9.043-9.309-13.695c-2.442-3.874-7.563-5.037-11.439-2.592    c-3.875,2.443-5.035,7.564-2.592,11.439c3.135,4.973,6.483,9.898,9.952,14.641c1.625,2.221,4.146,3.397,6.7,3.397    c1.7,0,3.414-0.521,4.89-1.6C64.533,406.082,65.338,400.894,62.633,397.197z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M95.894,434.005c-4.087-3.678-8.099-7.547-11.924-11.497c-3.186-3.291-8.435-3.376-11.727-0.189    c-3.291,3.186-3.376,8.436-0.189,11.727c4.088,4.223,8.376,8.358,12.744,12.289c1.586,1.427,3.57,2.129,5.546,2.129    c2.269,0,4.529-0.926,6.167-2.745C99.575,442.313,99.298,437.069,95.894,434.005z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g><g>
              <g>
                <path d="M35.692,142.346c-4.139-1.965-9.086-0.203-11.051,3.932c-1.302,2.74-2.571,5.54-3.773,8.324    c-1.816,4.204,0.121,9.086,4.326,10.902c1.072,0.462,2.187,0.682,3.284,0.682c3.209,0,6.266-1.874,7.618-5.007    c1.124-2.602,2.31-5.221,3.528-7.783C41.589,149.258,39.829,144.312,35.692,142.346z" data-original="#000000" className="active-path" data-old_color="#000000" fill="#FFFFFF" />
              </g>
            </g></g> </svg>
          <div style={{ paddingLeft: '10px', alignSelf: 'center' }}>
            <div className='name '>
              REFUND
            </div>
            <div className='plan-status'>
              {data.description}
            </div>
          </div>
        </Flex.Item>
        <Flex.Item className='right-content'>
          <div className='amount green'>
            {`+ ${data.points} Funds`}
          </div>
          <div className='date-time'>
            {`On ${moment(data.createdAt).format('DD/MM/YYYY [at] hh:mm A')}`}
          </div>
        </Flex.Item>
      </Flex>
    )
  }
  else if (data.type === 'REFERRAL') {
    return (
      <Flex justify='inbetween' className='history-row' key={key}>
        <Flex.Item className='left-content'>
          <svg width="40" height="31" viewBox="-5 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.4792 6.89585C24.767 6.89585 25 6.66289 25 6.375V2.20835C25 1.05933 24.0657 0.125 22.9167 0.125H2.08335C0.934326 0.125 0 1.05933 0 2.20835V6.375C0 6.66289 0.232959 6.89585 0.52085 6.89585C1.95669 6.89585 3.125 8.06416 3.125 9.5C3.125 10.9358 1.95669 12.1042 0.52085 12.1042C0.232959 12.1042 0 12.3371 0 12.625V16.7917C0 17.9407 0.934326 18.875 2.08335 18.875H22.9167C24.0657 18.875 25 17.9407 25 16.7917V12.625C25 12.3371 24.767 12.1042 24.4792 12.1042C23.0433 12.1042 21.875 10.9358 21.875 9.5C21.875 8.06416 23.0433 6.89585 24.4792 6.89585ZM20.8333 9.5C20.8333 11.3336 22.1939 12.8554 23.9583 13.1087V16.7917C23.9583 17.3659 23.4909 17.8333 22.9167 17.8333H2.08335C1.50913 17.8333 1.0417 17.3659 1.0417 16.7917V13.1087C2.8061 12.8554 4.1667 11.3336 4.1667 9.5C4.1667 7.66641 2.8061 6.14458 1.0417 5.89131V2.20835C1.0417 1.63413 1.50913 1.1667 2.08335 1.1667H22.9167C23.4909 1.1667 23.9583 1.63413 23.9583 2.20835V5.89131C22.1939 6.14458 20.8333 7.66641 20.8333 9.5Z" fill="white" />
            <path d="M6.77085 2.07812C6.48296 2.07812 6.25 2.31108 6.25 2.59897V3.77085C6.25 4.05874 6.48296 4.2917 6.77085 4.2917C7.05874 4.2917 7.2917 4.05874 7.2917 3.77085V2.59897C7.29165 2.31108 7.05874 2.07812 6.77085 2.07812Z" fill="white" />
            <path d="M6.77085 6.375C6.48296 6.375 6.25 6.60796 6.25 6.89585V7.9375C6.25 8.22539 6.48296 8.45835 6.77085 8.45835C7.05874 8.45835 7.2917 8.22539 7.2917 7.9375V6.89585C7.29165 6.60796 7.05874 6.375 6.77085 6.375Z" fill="white" />
            <path d="M6.77085 10.5417C6.48296 10.5417 6.25 10.7747 6.25 11.0626V12.1042C6.25 12.3921 6.48296 12.6251 6.77085 12.6251C7.05874 12.6251 7.2917 12.3921 7.2917 12.1042V11.0626C7.29165 10.7747 7.05874 10.5417 6.77085 10.5417Z" fill="white" />
            <path d="M6.77085 14.7083C6.48296 14.7083 6.25 14.9412 6.25 15.2291V16.2708C6.25 16.5586 6.48296 16.7916 6.77085 16.7916C7.05874 16.7916 7.2917 16.5586 7.2917 16.2708V15.2291C7.29165 14.9412 7.05874 14.7083 6.77085 14.7083Z" fill="white" />
            <path d="M17.4764 4.37916C17.2353 4.21945 16.9134 4.28405 16.7541 4.5236L10.5041 13.8986C10.3444 14.1381 10.409 14.4611 10.6486 14.6209C10.7376 14.6798 10.8378 14.7084 10.937 14.7084C11.1053 14.7084 11.2706 14.627 11.3708 14.4764L17.6208 5.10143C17.7805 4.86183 17.7159 4.53888 17.4764 4.37916Z" fill="white" />
            <path d="M13.5417 6.8959C13.5417 5.46006 12.6073 4.29175 11.4583 4.29175C10.3093 4.29175 9.375 5.46001 9.375 6.8959C9.375 8.33179 10.3093 9.50005 11.4583 9.50005C12.6074 9.50005 13.5417 8.33174 13.5417 6.8959ZM10.4167 6.8959C10.4167 6.04902 10.8937 5.3334 11.4583 5.3334C12.0229 5.3334 12.5 6.04902 12.5 6.8959C12.5 7.74277 12.0229 8.4584 11.4583 8.4584C10.8937 8.4584 10.4167 7.74272 10.4167 6.8959Z" fill="white" />
            <path d="M16.6666 9.5C15.5177 9.5 14.5833 10.6683 14.5833 12.1042C14.5833 13.54 15.5176 14.7083 16.6666 14.7083C17.8156 14.7083 18.75 13.54 18.75 12.1042C18.75 10.6683 17.8157 9.5 16.6666 9.5ZM16.6666 13.6667C16.102 13.6667 15.625 12.951 15.625 12.1042C15.625 11.2573 16.1021 10.5417 16.6666 10.5417C17.2312 10.5417 17.7083 11.2573 17.7083 12.1042C17.7083 12.951 17.2312 13.6667 16.6666 13.6667Z" fill="white" />
          </svg>
          <div style={{ paddingLeft: '10px', alignSelf: 'center' }}>
            <div className='name'>
              {titleCase(data.name)}
            </div>
            <div className={`plan-status `}>
              {/* {data.description} */}
            </div>
          </div>
        </Flex.Item>
        <Flex.Item className='right-content'>
          <div className='amount green'>
            {`+ ${data.points} Funds`}
          </div>
          <div className='date-time'>
            {`On ${moment(data.createdAt).format('DD/MM/YYYY [at] hh:mm A')}`}
          </div>
        </Flex.Item>
      </Flex>
    )
  }
  else {
    return null
  }
}