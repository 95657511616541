import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, from } from 'apollo-link'
import { ErrorLink } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import Cookies from 'universal-cookie'
import { RestLink } from 'apollo-link-rest';
import history from './history'

export const cacheData = new InMemoryCache()

function stripTypenames(obj, propToDelete) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property
      const newData = stripTypenames(obj[property], propToDelete)
      obj[property] = newData
    } else {
      if (property === propToDelete) {
        delete obj[property]
      }
    }
  }
  return obj
}

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripTypenames(operation.variables, '__typename')
  }
  return forward ? forward(operation) : null
})

const errorLink = new ErrorLink(
  ({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        if (message === 'Not Authorised!') {
          history.push('/logout')
        }
        return console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
      )
    }
    if (response) {
      response.errors.map(({ message, locations, path }) => {
        if (message === 'Not Authorised!') {
          history.push('/logout')
        }
        return console.log(
          `[Response error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
      )
    }
    if (networkError) {
      if (networkError.message === 'Not Authorised!') {
        history.push('/logout')
      }
      console.log(`[Network error]: ${networkError}`)
    }
  }
)

const link = createHttpLink({
  credentials: 'include',
  uri: `${process.env.REACT_APP_SERVER_GRAPH_URL}graphql`
})

const cookies = new Cookies()

const authMiddleware = new ApolloLink((operation, forward) => {
  const authorizationToken = cookies.get("token")
  operation.setContext({
    headers: {
      authorization: authorizationToken ? `Bearer ${authorizationToken}` : null,
    }
  })
  return forward(operation)
})

const restLink = new RestLink({ uri: process.env.REACT_APP_SERVER_GRAPH_URL, credentials: 'include' });

const client = new ApolloClient({
  cache: cacheData,
  link: from([removeTypenameMiddleware, errorLink, restLink, authMiddleware, link]),
  credentials: 'include'
})

cacheData.writeData({
  data: {
    checked: false,
    expectedCommission: 30000,
    isChecked: false,
    propertyFee: 30000
  }
})

export { client }