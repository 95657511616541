import React, { useEffect, useContext, Fragment, useState } from 'react';
import { Icon, WhiteSpace, WingBlank, Flex, Toast, Button } from 'antd-mobile';
import moment from 'moment'
import Navbar from '../../components/navbar'
import { AppContext } from '../../AppContext';
import { client } from '../../apollo';
import { GET_TRANSACTION } from './graphql/Queries'
import { GET_CURRENT_USER } from '../profile/graphql/Queries';
import Meta from '../../components/Meta';
import Loader from '../../components/loader';

const Transaction = (props) => {
  const { match: { params: { id } } } = props
  if (!id || id === undefined) {
    props.history.replace("/")
  }
  const { state, dispatch } = useContext(AppContext)
  const userId = state.currentUser.id
  const [transaction, setTransaction] = useState()

  useEffect(() => {
    if (userId && id) {
      client.query({ query: GET_TRANSACTION, variables: { id: id, fetchPolicy: 'network-only' } })
        .then(data => {
          if (data && data.data && data.data.transaction && data.data.transaction !== null) {
            setTransaction(data.data.transaction)
          } else {
            props.history.replace('/404')
          }
        })
        .catch(error => {
          Toast.info(error, 3)
          console.log({ error })
        })
    }
  }, [userId, id, props.history])

  useEffect(() => {
    client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
      .then(data => {
        dispatch({ type: 'CURRENT_USER', data: data.data.currentUser })
      })
      .catch(error => {
        console.log({ error })
      })
  }, [dispatch, props.history])

  const payStatus = 1
  const drawIcon = status => {
    if (status) {
      return <Icon type='check' size='lg' style={{ backgroundColor: '#aeca36' }} />
    }
    return <Icon type='cross' className='red' size='lg' style={{ backgroundColor: '#D34E4E' }} />
  }

  return (
    transaction ?
      <Fragment>
        <Meta title='Transaction' description='' />
        <Navbar title='TRANSACTION DETAILS'>
          <WingBlank className='flex-center' style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
            <Flex justify='center'>
              <div className='pay-status'>
                {drawIcon(payStatus)}
              </div>
            </Flex>
            <Flex justify='center' style={{ marginTop: '20px', color: 'white', fontSize: '2em' }}>
              <div>{transaction.amount} Funds</div>
            </Flex>
            <Flex justify='center' style={{ margin: '10px' }}>
              <div >Paid successfully at</div>
            </Flex>
            <Flex justify='center' style={{ margin: '10px', color: 'white', fontSize: '2em' }}>

              <div>
                {transaction.restaurant.name}
              </div>
              {/* <h4>
                  {transaction.restaurant.address}
                </h4> */}

            </Flex>
            <Flex justify='center' style={{ marginTop: '50px' }}>
              <div>{moment(transaction.createdAt).format('DD/MM/YYYY, hh:mm:ss A')}</div>
            </Flex>
            <WhiteSpace size='sm' />
            <Flex justify='center' >
              <div>Transaction id: {transaction.id}</div>
            </Flex>
            <WhiteSpace size='lg' />
            <WhiteSpace size='lg' />
            <Flex justify='center' >
              <Button className='btn' onClick={() => props.history.replace('/')}>Done</Button>
            </Flex>
          </WingBlank>
        </Navbar>
      </Fragment>
      :
      <Loader />
  );
}

export default Transaction;