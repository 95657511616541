import React from 'react';
import { Flex } from 'antd-mobile'

const ErrorMSG = () => {

  return (
    <Flex justify='center' style={{ width: '100%', height: '100%', color: '#dddddd' }}>
      <p>Something Went Wrong Please Try Again</p>
    </Flex>
  )
};

export default ErrorMSG