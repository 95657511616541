import React, { useContext } from 'react';
import { Flex, WingBlank, WhiteSpace, Toast } from 'antd-mobile';
import { withRouter } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { EmailShareButton, FacebookShareButton, WhatsappShareButton, } from 'react-share';
import { EmailIcon, FacebookIcon, WhatsappIcon, } from 'react-share';
import Navbar from '../components/navbar'
import { AppContext } from '../AppContext'
import '../index.css'

function Refer(props) {
  const { state } = useContext(AppContext)
  const code = state.currentUser.referralCode || 'XXXXXXX'
  // const msg = `Checkout ikoverk lounge and get 50 funds. Use code: ${code} while registeration. lounge.ikoverk.com/register/${code}`
  const url = `lounge.ikoverk.com/register/${code}`

  return (
    <Navbar title='REFER AND EARN'>
      <div className='refer-code main-div'>

        <h2>
          YOUR REFERRAL CODE
        </h2>
        <CopyToClipboard text={code}
          onCopy={() => Toast.success("Copied!", 1, null, false)}>
          <Flex className='refer-code-block' direction='column' >
            <Flex.Item className='refer-code code'>
              {code}
            </Flex.Item>
            <Flex.Item className='refer-code text'>
              TAP TO COPY
          </Flex.Item>
          </Flex>
        </CopyToClipboard>
        <WhiteSpace size='lg' />
        <WhiteSpace size='lg' />
        <WhiteSpace size='lg' />
        <Flex>
          <WingBlank>
            <div className='refer-description'>
              Refer a friend and you will get <b>50 funds</b> for each referal when they subscribe to the plan. On top of that each of your friends gets <b>50 funds</b> to use when they subscribe to any plan.
            </div>
          </WingBlank>
        </Flex>
        <WhiteSpace size='lg' />
        <WhiteSpace size='lg' />
        <WhiteSpace size='lg' />
        <Flex justify='between'>
          <Flex.Item>
            <WhatsappShareButton title={`Checkout Ikoverk lounge and get 50 funds. Use code: ${code} while registeration.`} url={url} separator={' '}>
              <WhatsappIcon size={50} round={true} />
            </WhatsappShareButton>
          </Flex.Item>
          <Flex.Item>
            <EmailShareButton subject={'Invitation'} body={`Checkout Ikoverk lounge and get 50 funds. Use code: ${code} while registeration.`} url={url} separator={' '}>
              <EmailIcon size={50} round={true} />
            </EmailShareButton>
          </Flex.Item>
          <Flex.Item>
            <FacebookShareButton url={url} quote={`Checkout Ikoverk lounge and get 50 funds. Use code: ${code} while registeration.`}>
              <FacebookIcon size={50} round={true} />
            </FacebookShareButton>
          </Flex.Item>
          {/* <Flex.Item>
            <InstapaperShareButton title={`Checkout Ikoverk lounge and get 50 funds. Use code: ${code} while registeration.`} url='lounge.ikoverk.com' separator={' '}>
              <InstapaperIcon size={40} round={true} />
            </InstapaperShareButton>
          </Flex.Item> */}
        </Flex>
      </div>
    </Navbar >
  )
}
export default withRouter(Refer)
