import React from 'react'
import { Carousel, Flex } from 'antd-mobile'
import Loader from '../../../components/loader'
import '../style/index.css'
import ccd1 from '../../../assets/ccd1.jpg'
import ccd2 from '../../../assets/ccd2.jpg'
import ccd3 from '../../../assets/ccd3.jpeg'

const CustomCarousel = (props) => {
  const data = [ccd1, ccd2, ccd3];
  const images = props.images ? props.images : data

  return (
    !images ? <Loader /> : (
      <Carousel autoplay={true} infinite>
        {images.map((img, key) => (
          <Flex>
            <img
              key={key}
              src={img}
              alt="Restaurant"
              className='carousel-image'
            />
          </Flex>
        ))}
      </Carousel>)
  )
}

export default CustomCarousel;