import gql from 'graphql-tag'

export const CHECK_USER = gql`
query checkUser($phone: String!){
  checkUser(where: { phone: $phone })
}
`
export const CHECK_REFERRAL_CODE = gql`
query verifyReferralCode($referralCode:String!){
  verifyReferralCode(referralCode:$referralCode)
}
`;
